<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Registreren" />
    <h1 class="text-center">{{ $t('order-without-registration-title') }}</h1>
    <p>{{ $t('fill-details-to-complete-order') }}.</p>

    <b-row>
      <b-col md="10" sm="12">
        <b-card>
          <b-form @submit.prevent="checkout">
            <b-row>
              <b-col cols="12">
                <h4>{{ $t('your-details') }}</h4>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-firstname"
                  label-for="input-firstname"
                >
                  <label>
                    {{ $t('firstname') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-firstname"
                    v-model="form.firstname"
                    :placeholder="$t('firstname')"
                    type="text"
                    name="firstname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-lastname"
                  label-for="input-lastname"
                >
                  <label>
                    {{ $t('lastname') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-lastname"
                    v-model="form.lastname"
                    :placeholder="$t('lastname')"
                    autocomplete="lastname"
                    type="text"
                    name="lastname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-email"
                  label-for="input-customer-email"
                >
                  <label>
                    {{ $t('emailaddress') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-email"
                    v-model="form.email"
                    :placeholder="$t('emailaddress')"
                    type="text"
                    maxlength="255"
                    name="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-email"
                  label-for="input-customer-email"
                >
                  <label>
                    {{ $t('repeat-emailaddress') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-email"
                    v-model="form.emailConfirmation"
                    :state="emailConfirmState"
                    :placeholder="$t('repeat-emailaddress')"
                    type="text"
                    maxlength="255"
                    name="email-confirmation"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-street" label-for="input-street">
                  <label>
                    {{ $t('street') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-street"
                    v-model="form.street"
                    :placeholder="$t('street')"
                    type="text"
                    maxlength="50"
                    name="street"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="6">
                <b-form-group
                  id="input-group-housenumber"
                  label-for="input-housenumber"
                >
                  <label>
                    {{ $t('housenumber') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-housenumber"
                    v-model="form.houseNumber"
                    :placeholder="$t('housenumber')"
                    type="text"
                    maxlength="10"
                    name="houseNumber"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-postal" label-for="input-postal">
                  <label>
                    {{ $t('zipcode') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-postal"
                    v-model="form.postcode"
                    :placeholder="$t('zipcode')"
                    type="text"
                    maxlength="10"
                    name="postcode"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-city" label-for="input-city">
                  <label>
                    {{ $t('city') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-city"
                    v-model="form.city"
                    :placeholder="$t('city')"
                    type="text"
                    name="city"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-tel" label-for="input-tel">
                  <label>
                    {{ $t('gsm') }}
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-tel"
                    v-model="form.CONTACT_MobilePhone"
                    :placeholder="$t('gsm')"
                    type="text"
                    name="mobiletel"
                    maxlength="50"
                    required
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <!-- Invoice details -->
              <b-col cols="12">
                <hr />
                <h4>{{ $t('invoice-details') }}</h4>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  id="invoiceSameAsOrganisationAddress"
                  v-model="invoiceSameAsOrganisationAddress"
                  name="invoiceSameAsOrganisationAddress"
                >
                  {{ $t('invoice-details-same-regular') }}
                </b-form-checkbox>
              </b-col>
              <b-col v-if="!invoiceSameAsOrganisationAddress" cols="12">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-name"
                      label-for="input-customer-name"
                    >
                      <label>
                        {{ $t('companyname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-companyname"
                        v-model="form.InvoiceCompanyName"
                        :placeholder="$t('companyname')"
                        :required="!invoiceSameAsOrganisationAddress"
                        type="text"
                        maxlength="50"
                        max="50"
                        name="InvoiceCompanyName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-invoice-vatnumber"
                      label-for="input-customer-invoice-vatnumber"
                    >
                      <label>{{ $t('companynumber') }}</label>
                      <b-form-input
                        id="input-customer-invoice-vatnumber"
                        v-model="form.VATNo"
                        :placeholder="$t('companynumber')"
                        type="text"
                        maxlength="30"
                        name="invoiceVatnumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-firstname"
                      label-for="input-customer-invoice-firstname"
                    >
                      <label>
                        {{ $t('firstname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-firstname"
                        v-model="form.InvoiceFirstName"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('firstname')"
                        type="text"
                        maxlength="50"
                        name="InvoiceFirstName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-lastname"
                      label-for="input-customer-invoice-lastname"
                    >
                      <label>
                        {{ $t('lastname') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-lastname"
                        v-model="form.InvoiceLastName"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('lastname')"
                        type="text"
                        maxlength="50"
                        name="InvoiceLastName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-street"
                      label-for="input-invoice-street"
                    >
                      <label>
                        {{ $t('street') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-street"
                        v-model="form.InvoiceStreet"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('street')"
                        type="text"
                        maxlength="50"
                        name="InvoiceStreet"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="6">
                    <b-form-group
                      id="input-group-invoice-housenumber"
                      label-for="input-invoice-housenumber"
                    >
                      <label>
                        {{ $t('housenumber') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-housenumber"
                        v-model="form.InvoiceHouseNumber"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('housenumber')"
                        type="text"
                        maxlength="10"
                        name="InvoiceHouseNumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-postal"
                      label-for="input-invoice-postal"
                    >
                      <label>
                        {{ $t('zipcode') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-postal"
                        v-model="form.InvoiceZipCode"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('zipcode')"
                        type="text"
                        maxlength="10"
                        name="InvoiceZipCode"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-city"
                      label-for="input-city"
                    >
                      <label>
                        {{ $t('city') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceCity"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('city')"
                        type="text"
                        maxlength="50"
                        name="InvoiceCity"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-phone"
                      label-for="input-phone"
                    >
                      <label>
                        {{ $t('telephonenumber') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceTel"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('telephonenumber')"
                        type="text"
                        maxlength="20"
                        oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                        name="InvoiceTel"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-email"
                      label-for="input-email"
                    >
                      <label>
                        {{ $t('emailaddress') }}
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-invoice-email"
                        v-model="form.InvoiceEmail"
                        :required="!invoiceSameAsOrganisationAddress"
                        :placeholder="$t('emailaddress')"
                        type="text"
                        maxlength="250"
                        name="InvoiceEmail"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- END invoice details -->

              <b-col>
                <hr />
              </b-col>

              <b-col cols="12">
                <b-form-checkbox
                  id="agree-with-terms"
                  v-model="form.agreedWithTerms"
                  :required="true"
                  name="agree-with-terms"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  {{ $t('i-agree-with') }}
                  <a href="https://rentmagic.net" target="_blank">{{
                    $t('the-rules')
                  }}</a>
                  {{ $t('of-the-companyname') }}
                  <span class="text-danger">*</span>
                </b-form-checkbox>
              </b-col>

              <b-col cols="12" class="text-right">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="danger"
                  class="mt-3 text-left"
                  >{{ errorMessage }}</b-alert
                >
                <b-button
                  :disabled="registering"
                  class="mb-2"
                  type="submit"
                  variant="primary"
                >
                  {{ $t('create-order') }}
                  <icon-spinner v-if="registering" />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { getCart } from '@/services/CartService'
import IconSpinner from '@/components/icons/IconSpinner'

const { anonymousCheckout } = require('@/services/AuthorizationService')

export default {
  components: { IconSpinner },
  data() {
    return {
      form: {
        username: '',
        email: '',
        street: '',
        houseNumber: '',
        houseNumberAddition: '',
        postcode: '',
        city: '',
        tel: '',
        customerOrganisationNumber: '',
        vatid: '',
        website: '',
        targetAudience: '',
        firstname: '',
        lastname: '',
        contactEmail: '',
        contactStreet: '',
        contactHouseNumber: '',
        contactPostcode: '',
        contactCity: '',
        CONTACT_Tel: '',
        CONTACT_MobilePhone: '',
        CUST_Notes: '',
        newsletter: false,
        agreedWithTerms: false,
        CUST_SocialMedia: '',

        InvoiceCompanyName: '',
        InvoiceFirstName: '',
        InvoiceLastName: '',
        InvoiceStreet: '',
        InvoiceHouseNumber: '',
        InvoiceHouseNumberAddition: '',
        InvoiceZipCode: '',
        InvoiceCity: '',
        InvoiceTel: '',
        InvoiceEmail: '',
        VATNo: ''
      },
      registering: false,
      error: false,
      errorMessage: null,
      invoiceSameAsOrganisationAddress: true
    }
  },
  metaInfo: {
    title: `Registreren`
  },
  computed: {
    emailConfirmState() {
      if (this.form.email != '') {
        if (this.form.email == this.form.emailConfirmation) {
          return true
        }
        return false
      }
      return null
    }
  },
  methods: {
    async checkout(event) {
      this.registering = true
      event.preventDefault()

      let InvoiceCompanyName = this.form.InvoiceCompanyName
      let InvoiceStreet = this.form.InvoiceStreet
      let InvoiceHouseNumber = this.form.InvoiceHouseNumber
      let InvoiceHouseNumberAddition = this.form.InvoiceHouseNumberAddition
      let InvoiceZipCode = this.form.InvoiceZipCode
      let InvoiceCity = this.form.InvoiceCity
      let InvoiceTel = this.form.InvoiceTel
      let InvoiceEmail = this.form.InvoiceEmail
      let InvoiceFirstName = this.form.InvoiceFirstName
      let InvoiceLastName = this.form.InvoiceLastName

      if (this.invoiceSameAsOrganisationAddress) {
        InvoiceCompanyName = this.form.companyName
        InvoiceStreet = this.form.street
        InvoiceHouseNumber = this.form.houseNumber
        InvoiceHouseNumberAddition = this.form.houseNumberAddition
        InvoiceZipCode = this.form.postcode
        InvoiceCity = this.form.city
        InvoiceTel = this.form.tel || this.form.mobiletel
        InvoiceEmail = this.form.email
        InvoiceFirstName = this.form.firstname
        InvoiceLastName = this.form.lastname
      }
      const result = await anonymousCheckout({
        username: this.form.email,
        email: this.form.email,
        password: this.form.password,
        street: this.form.street,
        customerOrganisationNumber: this.form.customerOrganisationNumber,
        houseNumber: this.form.houseNumber,
        houseNumberAddition: this.form.houseNumberAddition,
        postcode: this.form.postcode,
        city: this.form.city,
        tel: this.form.tel,
        companyName: `${this.form.firstname} ${this.form.lastname}`,
        website: this.form.website,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        CONTACT_email: this.form.email,
        CONTACT_tel: this.form.CONTACT_Tel,
        CONTACT_MobilePhone: this.form.CONTACT_MobilePhone,
        InvoiceCompanyName,
        InvoiceStreet,
        InvoiceHouseNumber,
        InvoiceHouseNumberAddition,
        InvoiceZipCode,
        InvoiceCity,
        InvoiceTel,
        InvoiceEmail,
        InvoiceFirstName,
        InvoiceLastName,
        VATNo: this.form.VATNo,
        Locale: this.$i18n.locale
      })

      if (
        result.data.Message === 'The entered e-mail address is unauthorized.' ||
        result.data.Message ===
          'This e-mailaddress is already in use by another user.'
      ) {
        this.displayErrorMessage(this.$t('email-in-use-register'))

        return
      }
      if (
        result.data.Message != 'InvalidModelState' &&
        result.data?.ReservationID !== null
      ) {
        this.$store.commit('setLatestReservation', {
          reservation: result
        })
        this.items = await getCart({ notes: this.notes })
        this.$router.push({
          name: 'reservation-confirmation',
          props: { fields: this.fields, reservation: result },
          params: { lang: this.$i18n.locale }
        })
      } else {
        this.displayErrorMessage(
          this.$t('something-went-wrong-making-your-account')
        )
        return
      }

      this.registering = false
    },
    displayErrorMessage: function(errorMessage) {
      this.registering = false
      this.error = true
      this.errorMessage = errorMessage
    }
  }
}
</script>
